import moment from 'moment';
export default {
    isIE() {
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
            return true;
        } else {
            return false;
        }
    },
    getOrderStatus(statusId) {
        switch (statusId) {
            case 0:
                return '待付款';
            case 1:
                return '待发货';
            case 2:
                return '已发货';
            case 3:
                return '已收货';
            case 4:
                return '已完成';
            case 5:
                return '已关闭';
            case -1:
                return '退款中';
            default:
                return '已取消';
        }
    },
    // 获取剩余时间与当前时间时间段: 时间戳为 秒
    getTimeRange(endTime, now) {
        if (endTime <= now) {
            return '00:00';
        }
        let overTime = moment(endTime * 1000);
        let overSeconds = overTime.diff(now, 'seconds');
        let overMinutes = Math.floor(overSeconds / 60);
        let remainSeconds = overSeconds % 60;
        return `${overMinutes >= 10 ? overMinutes : '0' + overMinutes}:${
            remainSeconds >= 10 ? remainSeconds : '0' + remainSeconds
            }`;
    },
    //处理通用自定义时间
    formatTime(time) {
        if (typeof (time) != 'number') {
            time = parseInt(time)
        }
        if (time > 100000000000) {
            time = moment(time)
        } else {
            time = moment.unix(time)
        }
        let now = moment().startOf("day")
        let diff = now.diff(time, "day");
        if (diff == 0 && time > now) {
            return time.format("HH:mm");
        } else if (diff == 0 && time < now) {
            return time.format("昨天 HH:mm");
        } else {
            return time.format("MM月DD日");
        }
    },
    // 失去焦点函数
    lostFocus(event){
        let target =event.target;
        if(target.nodeName == "I" || target.nodeName == "SPAN") {
          target = event.target.parentNode;
        }
        target.blur();
    }
}